var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.data.block, attrs: { id: "banner-type4" } },
    [
      _c(
        "swiper",
        {
          ref: "slider",
          staticClass: "swiper",
          attrs: { options: _vm.swiperOption },
        },
        _vm._l(_vm.banners, function (banner, index) {
          return _c("swiper-slide", { key: banner._id + index }, [
            _c("div", [
              _c("img", {
                staticClass: "slider-img rounded",
                attrs: {
                  contain: "",
                  width: "100%",
                  src: _vm.baseImgUrl + banner.mobile_image || _vm.image,
                },
                on: {
                  click: function ($event) {
                    return _vm.clickBanner(banner.mobile_hyperlink, _vm.$router)
                  },
                },
              }),
            ]),
          ])
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }