<template>
  <!-- BANNER TYPE 4 -->
  <div
    id="banner-type4"
    :class="data.block"
  >
    <swiper
      ref="slider"
      class="swiper"
      :options="swiperOption"
    >
      <swiper-slide
        v-for="(banner,index) in banners"
        :key="banner._id + index"
      >
        <div>
          <img
            contain
            width="100%"
            class="slider-img rounded"
            :src="baseImgUrl + banner.mobile_image || image"
            @click="clickBanner(banner.mobile_hyperlink, $router)"
          >
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { clickBannerEv } from './clickBanner'

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters(['baseImgUrl', 'theme']),

    ...mapState({
      bannerDelay: state => state.websiteData.settings.banner_interval,
    }),

    banners() {
      return this.data.data.banner
    },

    swiperOption() {
      return {
        autoplay: {
          delay: +this.bannerDelay * 1000 || 3000,
        },

        loop: true,
        slidesPerView: 1.1,
        spaceBetween: 8,
        centeredSlides: true,
      }
    },

    customPaginationColor() {
      const themePrimaryColor = this.$vuetify.theme.themes[this.theme]?.primary
      // 目前依 primary 顯色
      return {
        '--swiper-theme-color': themePrimaryColor || 'white',
      }
    },
  },

  methods: {
    clickBanner(hyperlink, router) {
      clickBannerEv(hyperlink, router)
    },
  },
}
</script>

<style lang="scss" scoped>

</style>
